import { template as template_ddc33f6a093541b98460266b5f581eb1 } from "@ember/template-compiler";
const FKText = template_ddc33f6a093541b98460266b5f581eb1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
