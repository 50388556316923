import { template as template_90f2f5146cda443d8bf12561b3e19dee } from "@ember/template-compiler";
const WelcomeHeader = template_90f2f5146cda443d8bf12561b3e19dee(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
