import { template as template_0c5f46a9a53e49c18eaca290339a515c } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKField from "discourse/form-kit/components/fk/field";
const FKInputGroup = template_0c5f46a9a53e49c18eaca290339a515c(`
  <div class="form-kit__input-group">
    {{yield
      (hash
        Field=(component
          FKField
          errors=@errors
          addError=@addError
          data=@data
          set=@set
          remove=@remove
          registerField=@registerField
          unregisterField=@unregisterField
          triggerRevalidationFor=@triggerRevalidationFor
          showMeta=false
        )
      )
    }}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKInputGroup;
