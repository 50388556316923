import { template as template_f31282c55f2a4fe4a656a5ec08b8415c } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_f31282c55f2a4fe4a656a5ec08b8415c(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_f31282c55f2a4fe4a656a5ec08b8415c(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_f31282c55f2a4fe4a656a5ec08b8415c(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
